<template>
  <v-container fluid>
    <v-row class="mt-5">
      <v-col md="1">
        <IdCardReader
          label="Scan Document"
          docType="doc"
          @data="
            (data) => {
              setCardData(data);
            }
          "
        />
      </v-col>
      <v-col md="1">
        <IdCardReader
          label="Scan ID"
          docType="chip"
          @data="
            (data) => {
              setCardData(data);
            }
          "
        />
      </v-col>
    </v-row>
    <v-card>
      <v-row no-gutters="" class="mt-6">
        <v-col md="6">
          <ul>
            <li v-for="(udata, index) in user" :key="index">
              <div
                v-if="
                  index != 'fullImage' &&
                  index != 'fullImageIR' &&
                  index != 'faceImage'
                "
              >
                <strong>{{ index }}: </strong> {{ udata }}
              </div>
              <div v-if="index == 'id_proof'">
                <strong>{{ index }}: </strong>
                <img :src="showImage(udata)" />
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import IdCardReader from "../../components/EmiratesId/IdCardReader.vue";
export default {
  components: {
    IdCardReader,
  },
  data() {
    return {
      user: [],
      src: "",
    };
  },
  mounted() {},
  methods: {
    setCardData(data) {
      console.log("ddddddd", data);
      this.user = data;
      this.$forceUpdate();
    },
    showImage(filedata) {
      let src = "";

      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result);
        src = reader.result;
        return src;
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(filedata);
    },
  },
};
</script>

<style>
</style>
