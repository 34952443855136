var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"md":"1"}},[_c('IdCardReader',{attrs:{"label":"Scan Document","docType":"doc"},on:{"data":function (data) {
            _vm.setCardData(data);
          }}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('IdCardReader',{attrs:{"label":"Scan ID","docType":"chip"},on:{"data":function (data) {
            _vm.setCardData(data);
          }}})],1)],1),_c('v-card',[_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"6"}},[_c('ul',_vm._l((_vm.user),function(udata,index){return _c('li',{key:index},[(
                index != 'fullImage' &&
                index != 'fullImageIR' &&
                index != 'faceImage'
              )?_c('div',[_c('strong',[_vm._v(_vm._s(index)+": ")]),_vm._v(" "+_vm._s(udata)+" ")]):_vm._e(),(index == 'id_proof')?_c('div',[_c('strong',[_vm._v(_vm._s(index)+": ")]),_c('img',{attrs:{"src":_vm.showImage(udata)}})]):_vm._e()])}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }